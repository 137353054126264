import React from "react";
import "./Hero.css"; // Import the CSS file for styling
import Wave from "react-wavify";
import blockchainAnimation from "../images/hero/blockchain copy.png"; // Use a PNG image

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="wave-container">
        <Wave
          className="wave"
          fill="url(#gradient)"
          paused={false}
          options={{
            height: 20, // Reduced height for a subtle wave effect
            amplitude: 30, // Reduced amplitude for smoother waves
            speed: 0.15, // Slightly faster speed
            points: 6, // Number of points in the wave
          }}
        >
          <defs>
            <linearGradient id="gradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#000C40" />
              <stop offset="100%" stopColor="#1C1E1F" />
            </linearGradient>
          </defs>
        </Wave>
      </div>
      <div className="title-container">
        <div className="animation-container">
          <img
            src={blockchainAnimation}
            alt="Blockchain Animation"
            className="animation"
          />
        </div>
        <div className="text-container">
          <h1 className="title">Chainworks</h1>
          <p className="sub-title">Blockchain & AI Products and Services</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;