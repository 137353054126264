import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core"
import Stars from "../stars"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUs from "../components/aboutUs"
import Hero from "../components/hero"
import Team from "../components/team"
import Contact from "../components/contact"
import NavBar from "../components/navBar"
import Awards from "../components/awards"
import Approach from "../components/approach"
import Projects from "../components/projects"
import Security from "../components/security"
import AnnaulReports from "../components/annualReports"
import WhyChooseUs from "../components/whyChooseUs"

const useStyles = makeStyles(theme => ({
  stars: {
    position: "absolute",
    background: "linear-gradient(135deg, #2B7594 0%, #655900 100%)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  body: {
    zIndex: 1,
    background: "rgba(246, 249, 252, 0.86)",
    borderRadius: "20px",
    borderLeft: "purple solid",
    borderRight: "purple solid",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10vh",
      marginRight: "10vh",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5vh",
      marginRight: "5vh",
    },
  },
  pageContainer: {
    position: "relative",
    paddingBottom: "5px",
  },
  sectionContainer: {
    width: "100%",
  },
}))

const IndexPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Home" />
      <NavBar />
      <Grid className={classes.pageContainer} container>
        <Grid id="stars" className={classes.stars} item>
          <Stars width="100%" height="100%" />
        </Grid>
        <Grid id="hero" container item>
          <Hero />
        </Grid>
        <Grid className={classes.body} id="body" container item>
          <div className={classes.sectionContainer} id="aboutUs">
            <AboutUs />
          </div>
          <div className={classes.sectionContainer} id="projects">
            <Projects />
          </div>
          <div className={classes.sectionContainer} id="projects">
            <WhyChooseUs />
          </div>
          <div className={classes.sectionContainer} id="approach">
            <Approach />
          </div>
          <div className={classes.sectionContainer} id="approach">
            <Security />
          </div>
          <div className={classes.sectionContainer} id="team">
            <Team />
          </div>
          <div className={classes.sectionContainer} id="awards">
            <Awards />
          </div>
          <div className={classes.sectionContainer} id="annaulReports">
            <AnnaulReports />
          </div>
          <div className={classes.sectionContainer} id="contactUs">
            <Contact />
          </div>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage
