import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery,
  withStyles,
  Grid,
  TextField,
} from "@material-ui/core"
import SendIcon from "mdi-material-ui/Send"
import More from "mdi-material-ui/ChevronDoubleRight"
import { green } from "@material-ui/core/colors"
import { useSnackbar } from "notistack"

const GreenButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button)

const ValidationTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderLeftWidth: 6,
        padding: "4px !important",
      },
    },
  },
})(TextField)

const ContactForm = () => {
  const { enqueueSnackbar } = useSnackbar()

  const submitForm = async formData => {
    const url = "https://getform.io/f/cd6330ca-c449-4ae2-8982-2bb955ae030e"
    let response = {
      success: false,
    }
    try {
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      if (!rawResponse.ok) {
        throw Error(rawResponse.statusText)
      }
      response = await rawResponse.json()
    } catch (err) {
      response.success = false
    }
    return response.success
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  return (
    <form
      style={{ marginTop: "30px" }}
      noValidate
      autoComplete="off"
      name="contact"
    >
      <Grid container direction="column">
        <Grid item container direction="row" spacing={3}>
          <Grid item sm={6} xs={12}>
            <ValidationTextField
              id="contactName"
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <ValidationTextField
              id="contactEmail"
              type="email"
              fullWidth
              label="Email"
              required
              variant="outlined"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <ValidationTextField
            id="contactMessage"
            required
            multiline
            rows={6}
            fullWidth
            label="Message"
            variant="outlined"
            value={message}
            onChange={e => {
              setMessage(e.target.value)
            }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginTop: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{
              borderRadius: "500px",
            }}
            endIcon={<SendIcon />}
            onClick={() => {
              submitForm({ email, name, message })
                .then(success => {
                  if (success) {
                    setName("")
                    setEmail("")
                    setMessage("")
                    enqueueSnackbar("Thanks!", { variant: "success" })
                  } else {
                    enqueueSnackbar("Please try again", { variant: "error" })
                  }
                })
                .catch(() => {
                  enqueueSnackbar("Please try again", { variant: "error" })
                })
            }}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
const ContactModal = ({ buttonText }) => {
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <GreenButton
        variant="outlined"
         
        disableElevation
        endIcon={<More />}
        onClick={() => setOpen(true)}
      >
        {buttonText}
      </GreenButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{buttonText}</DialogTitle>
        <DialogContent>
          <ContactForm />
        </DialogContent>
      </Dialog>
    </>
  )
}
ContactModal.propTypes = {
  buttonText: PropTypes.string,
}
ContactModal.defaultProps = {
  buttonText: "Contact Us",
}

export default ContactModal
