import React from "react"
import { Typography, Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "60px 20px",
    background:   " #66A5AD",
    color: "#C4DFE6",
    textAlign: "center",
    borderRadius: "16px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.25)",
    marginTop: theme.spacing(0.3),
  },
  header: {
    fontFamily: "'Patua One', cursive",
    fontSize: "2.8rem",
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    background: "white",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textTransform: "uppercase",
    letterSpacing: "2px",
    marginLeft: "10%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.2rem",
      marginLeft: "5%",
    },
  },
  text: {
    marginTop: "20px",
    fontSize: "1.2rem",
    lineHeight: "1.8",
    textAlign: "justify",
    margin: "0 10%",
    color: "rgba(255, 255, 255, 0.9)",
    [theme.breakpoints.down("sm")]: {
      margin: "0 5%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
}))

const AboutUs = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.container} container justifyContent="center">
      <Typography variant="h4" className={classes.header}>
        About Us & Our Mission
      </Typography>
      <Typography className={classes.text}>
      At Chainworks.io, our mission is to empower enterprises and governments to embrace the transformative potential of blockchain technology. By focusing on innovative, secure, and scalable solutions, we aim to revolutionize the way businesses operate, ensuring transparency, efficiency, and trust. With over 15 years of collective expertise in product research, development, and implementation, we are committed to guiding our clients from ideation to execution, helping them thrive in a rapidly evolving digital economy.
      </Typography>
    </Grid>
  )
}

export default AboutUs
