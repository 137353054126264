import React from "react";
import Img from "gatsby-image";
import {
  Typography,
  Grid,
  makeStyles,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
// import Lottie from "react-lottie";
import analysisData from "../images/approach/animations/analysis.json";
import developmentData from "../images/approach/animations/development.json";
import iterationData from "../images/approach/animations/iteration.json";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "40px 20px",
    backgroundColor: "#f8f9fa",
  },
  sectionHeader: {
    marginBottom: "20px",
    color: "#2c3e50",
    fontWeight: 600,
    textTransform: "uppercase",
    fontSize: "2rem",
    textAlign: "center",
  },
  sectionText: {
    marginBottom: "30px",
    color: "#7f8c8d",
    fontSize: "1.2rem",
    textAlign: "center",
    lineHeight: 1.6,
  },
  card: {
    height: "100%",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "translateY(-8px)",
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
    },
  },
  cardMedia: {
    backgroundColor: "#ffffff",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardTitle: {
    marginTop: "15px",
    color: "#34495e",
    fontWeight: 600,
  },
  cardContent: {
    padding: "20px",
    color: "#ffffff",
    fontSize: "0.9rem",
    lineHeight: 1.5,
  },
  toolsGrid: {
    marginTop: "30px",
  },
  image: {
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  },
}));

const Approach = () => {
  const classes = useStyles();
  const imageData = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "approach" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const imgEdges = imageData.allFile.edges.sort((a, b) =>
    a.node.name.localeCompare(b.node.name)
  );

  const animationCards = [
    {
      animationData: analysisData,
      title: "Analysis",
      description:
        "We prioritize identifying the correct problem before attempting a solution. We collaborate with customers to understand systems, stakeholders, and define an initial development scope.",
      bgColor: "#3498db",
    },
    {
      animationData: developmentData,
      title: "Development",
      description:
        "Our expertise in development frameworks allows us to create scaffolding projects for feedback. This iterative process ensures better problem-solving.",
      bgColor: "#f1c40f",
    },
    {
      animationData: iterationData,
      title: "Iteration",
      description:
        "We design modular, extensible products that evolve with stakeholder feedback. Starting with a solid core, our solutions grow with incremental updates.",
      bgColor: "#2ecc71",
    },
  ];

  return (
    <Grid className={classes.container} container>
      <Typography className={classes.sectionHeader}>Tools & Approach</Typography>
      <Typography className={classes.sectionText}>
        True to Agile methodology, we build solutions that tackle core business
        problems and evolve based on stakeholder feedback. Our approach is
        three-pronged:
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {animationCards.map((card, index) => (
          <Grid item md={4} sm={6} xs={12} key={index}>
            <Card className={classes.card}>
              {/* <CardMedia className={classes.cardMedia}>
                <Lottie
                  options={{
                    loop: true,
                    animationData: card.animationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width="150px"
                />
                <Typography className={classes.cardTitle} variant="h6">
                  {card.title}
                </Typography>
              </CardMedia> */}
              <CardContent
                className={classes.cardContent}
                style={{ backgroundColor: card.bgColor }}
              >
                <Typography variant="body2">{card.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography className={classes.sectionText} style={{ marginTop: "40px" }}>
        We bring expertise from diverse platforms to provide optimal solutions
        in terms of features, pricing, and security. Here are some tools and
        frameworks we specialize in:
      </Typography>
      <Grid   container spacing={8}>
        {imgEdges.map(i => (
          <Grid item md={2} sm={3} xs={3} key={i.node.id}>
            <Img
               imgStyle={{
                height: "100%",
                // objectFit: "contain",
              }}
              fluid={i.node.childImageSharp.fluid}
              alt="team"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Approach
