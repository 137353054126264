import React, { useEffect } from "react"
import { Typography, Grid, makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.css"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

const MediaArticle = ({ image, articleLink, title }) => {
  return (
    <Grid
      container
      item
      direction="row"
      sm={6}
      xs={12}
      style={{ border: "outset", borderWidth: "1px" }}
    >
      <Grid container item xs={3} justify="center" alignItems="center">
        <Grid item xs={12}>
          <Img
            style={{ height: "100px" }}
            imgStyle={{
              objectFit: "contain",
            }}
            fluid={image.node.childImageSharp.fluid}
            alt="media"
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={9}
        justify="center"
        alignItems="center"
        style={{ paddingLeft: "10px" }}
      >
        <Typography variant="body2" style={{ textTransform: "uppercase" }}>
          <strong>
            <a href={articleLink} target="_blank" rel="noreferrer">
              {title}
            </a>
          </strong>
        </Typography>
      </Grid>
    </Grid>
  )
}
MediaArticle.propTypes = {
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  articleLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const MediaMarquee = ({ mediaImgEdges }) => {
  let flickity = null
  let isPaused = false
  const tickerSpeed = 2

  const update = () => {
    if (isPaused) return
    if (flickity.slides) {
      flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth
      flickity.selectedIndex = flickity.dragEndRestingSelect()
      flickity.updateSelectedSlide()
      flickity.settle(flickity.x)
    }
    window.requestAnimationFrame(update)
  }

  const pause = () => {
    isPaused = true
  }

  const play = () => {
    if (isPaused) {
      isPaused = false
      window.requestAnimationFrame(update)
    }
  }

  useEffect(() => {
    const slideshowEl = document.querySelector(".mediacarousel")
    slideshowEl.addEventListener("mouseenter", pause, false)
    slideshowEl.addEventListener("focusin", pause, false)
    slideshowEl.addEventListener("mouseleave", play, false)
    slideshowEl.addEventListener("focusout", play, false)

    flickity.x = 0
    flickity.on("dragStart", () => {
      isPaused = true
    })

    update()
  }, [])

  return (
    <Flickity
      options={{
        autoPlay: false,
        selectedAttraction: 0.015,
        friction: 0.25,
        initialIndex: 0,
        wrapAround: true,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
      }}
      flickityRef={ref => {
        flickity = ref
      }}
      className="mediacarousel"
      elementType="div"
    >
      {[
        {
          image: "toi_logo.webp",
          articleLink:
            "https://timesofindia.indiatimes.com/city/nagpur/organ-transplant-hosps-made-aware-of-movement/articleshow/71157661.cms",
          title: "Organ transplant: Hospitals made aware of movement",
        },
        {
          image: "loksatta_logo.png",
          articleLink:
            "https://www.loksatta.com/mumbai-news/organ-transplants-registration-online-in-government-hospitals-zws-70-1930058/",
          title: "अवयवदान आणि प्रत्यारोपण करणाऱ्या रुग्णालयांची नोंदणी ऑनलाइन",
        },
        {
          image: "dailyhunt_logo.png",
          articleLink:
            "https://m.dailyhunt.in//news/india/english/my+medical+mantra+english-epaper-medmanen/hospitals+to+now+register+online+for+licenses+to+conduct+organ+transplants+retrieval-newsid-125131062",
          title:
            "Hospitals to now register online for licenses to conduct organ transplants, retrieval",
        },
        {
          image: "yahoonews_logo.png",
          articleLink:
            "https://in.news.yahoo.com/hospitals-register-online-conduct-organ-072117204.html",
          title:
            "Hospitals will have to register online to conduct organ transplant: Directorate of Health Services",
        },
        {
          image: "maritimegw_logo.png",
          articleLink:
            "http://www.maritimegateway.com/odex-launches-first-blockchain-based-ekyc-platform-indian-shipping-industry-2/",
          title:
            "ODEX LAUNCHES THE FIRST BLOCKCHAIN BASED EKYC PLATFORM IN THE INDIAN SHIPPING INDUSTRY",
        },
      ].map(article => {
        const imgEdge = mediaImgEdges.filter(
          m => m.node.childImageSharp.fluid.originalName === article.image
        )[0]
        return (
          <MediaArticle
            key={article.articleLink}
            mediaImgEdges={mediaImgEdges}
            image={imgEdge}
            articleLink={article.articleLink}
            title={article.title}
          />
        )
      })}
    </Flickity>
  )
}
MediaMarquee.propTypes = {
  mediaImgEdges: PropTypes.arrayOf(PropTypes.any).isRequired,
}

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "20px",
  },
  text: {
    marginTop: "20px",
  },
  mediaText: {
    marginTop: "50px",
  },
  header: {
    fontFamily: "Patua One",
  },
  image: {
    height: "200px",
    width: "250px",
  },
  carouselContainer: {
    [theme.breakpoints.down("lg")]: {
      width: `80%`,
      maxWidth: `${theme.breakpoints.values.md}px`,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: `${theme.breakpoints.values.sm}px`,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "320px",
    },
  },
}))

const Awards = () => {
  const classes = useStyles()
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { in: ["awards", "awards/media"] } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
            relativeDirectory
          }
        }
      }
    }
  `)
  const awardsImgEdges = imageData.allFile.edges.filter(
    e => e.node.relativeDirectory === "awards"
  )
  const mediaImgEdges = imageData.allFile.edges.filter(
    e => e.node.relativeDirectory === "awards/media"
  )
  return (
    <Grid className={classes.container} item>
      <Typography variant="h4" className={classes.header}>
        Awards & Recognition
      </Typography>
      <Typography className={classes.text}>
        Some recognition we have received for our products.
      </Typography>
      <Grid container justify="center" style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <Flickity
            options={{
              initialIndex: 0,
              wrapAround: true,
              draggable: true,
              prevNextButtons: false,
              autoPlay: true,
              pauseAutoPlayOnHover: true,
            }}
            className="carousel"
            elementType="div"
          >
            {awardsImgEdges.map(({ node }) => (
              <Zoom key={node.id}>
                <Img
                  className={classes.image}
                  imgStyle={{
                    height: "100%",
                    objectFit: "contain",
                  }}
                  fluid={node.childImageSharp.fluid}
                  alt="team"
                />
              </Zoom>
            ))}
          </Flickity>
        </Grid>
      </Grid>
      <Typography className={classes.mediaText}>
        Media our products have been featured in
      </Typography>
      <Grid container style={{ marginTop: "25px" }}>
        <Grid item xs={12}>
          <MediaMarquee mediaImgEdges={mediaImgEdges} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Awards
