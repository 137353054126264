import React, { useState } from "react"
import {
  Typography,
  Grid,
  makeStyles,
  Link,
  TextField,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Button,
} from "@material-ui/core"
import PhoneIcon from "mdi-material-ui/Phone"
import AtIcon from "mdi-material-ui/At"
import SendIcon from "mdi-material-ui/Send"
import MapMarkerIcon from "mdi-material-ui/MapMarker"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useSnackbar } from "notistack"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: theme.spacing(4),
    backgroundColor: "#f9f9f9",
    borderRadius: theme.spacing(1),
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  text: {
    marginTop: theme.spacing(2),
    lineHeight: 1.6,
    color: theme.palette.text.secondary,
  },
  header: {
    fontFamily: "Patua One, sans-serif",
    marginBottom: theme.spacing(3),
    textTransform: "capitalize",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  form: {
    marginTop: theme.spacing(3),
    backgroundColor: "#ffffff",
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  button: {
    borderRadius: "50px",
    padding: theme.spacing(1, 4),
    textTransform: "none",
    fontSize: "1rem",
  },
  list: {
    marginTop: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(2),
  },
  iconLinks: {
    marginTop: theme.spacing(3),
  },
}))

const ValidationTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: theme.spacing(1),
      "&.Mui-focused fieldset": {
        borderLeftWidth: 6,
        padding: "4px !important",
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField)

const ContactForm = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const submitForm = async (formData) => {
    const url = "https://getform.io/f/cd6330ca-c449-4ae2-8982-2bb955ae030e"
    let response = { success: false }
    try {
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      if (!rawResponse.ok) {
        throw Error(rawResponse.statusText)
      }
      response = await rawResponse.json()
    } catch (err) {
      response.success = false
    }
    return response.success
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  return (
    <form
      className={classes.form}
      noValidate
      autoComplete="off"
      name="contact"
    >
      <Grid container direction="column" spacing={3}>
        <Grid item container spacing={3}>
          <Grid item sm={6} xs={12}>
            <ValidationTextField
              id="contactName"
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <ValidationTextField
              id="contactEmail"
              type="email"
              fullWidth
              label="Email"
              required
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ValidationTextField
            id="contactMessage"
            required
            multiline
            rows={6}
            fullWidth
            label="Message"
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            endIcon={<SendIcon />}
            onClick={() => {
              submitForm({ email, name, message })
                .then((success) => {
                  if (success) {
                    setName("")
                    setEmail("")
                    setMessage("")
                    enqueueSnackbar("Thanks!", { variant: "success" })
                  } else {
                    enqueueSnackbar("Please try again", { variant: "error" })
                  }
                })
                .catch(() => {
                  enqueueSnackbar("Please try again", { variant: "error" })
                })
            }}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

const Contact = () => {
  const classes = useStyles()
  const imageData = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const imgEdges = imageData.allImageSharp.edges
  const waImgEdge = imgEdges.find(
    (e) => e.node.fluid.originalName === "whatsapp.png"
  )
  const callImgEdge = imgEdges.find(
    (e) => e.node.fluid.originalName === "call.png"
  )
  const mailImgEdge = imgEdges.find(
    (e) => e.node.fluid.originalName === "email.png"
  )
  const locImgEdge = imgEdges.find(
    (e) => e.node.fluid.originalName === "location.png"
  )
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Grid className={classes.container}>
      <Typography variant="h4" className={classes.header}>
        Contact Us
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.text}>
          Ready to transform your business with blockchain? Partner with Chainworks.io for innovative, secure, and scalable solutions that redefine the way you operate. Let’s build the future, together.
            <span role="img" aria-label="Wave">
              👋
            </span>
          </Typography>
          <ContactForm />
        </Grid>
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <AtIcon color="primary" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">hi@chainworks.io</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <PhoneIcon color="primary" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">+91 99679 76846</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <MapMarkerIcon color="primary" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">
                  M10, SINE,
                  <br />
                  IIT Bombay,
                  <br />
                  Mumbai
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Grid item container spacing={isSmUp ? 0 : 4} justify="space-around">
            <Grid item sm={1} xs={6}>
              <Link
                href="https://wa.link/zrgiok"
                target="_blank"
                rel="noopener"
              >
                <Img fluid={waImgEdge.node.fluid} alt="WhatsApp" />
              </Link>
            </Grid>
            <Grid item sm={1} xs={6}>
              <Link href="tel:+919967976846">
                <Img fluid={callImgEdge.node.fluid} alt="Call" />
              </Link>
            </Grid>
            <Grid item sm={1} xs={6}>
              <Link
                href="mailto:hi@chainworks.io"
                target="_blank"
                rel="noopener"
              >
                <Img fluid={mailImgEdge.node.fluid} alt="Mail" />
              </Link>
            </Grid>
            <Grid item sm={1} xs={6}>
              <Link
                href="https://goo.gl/maps/3cnez2CgvuEYBQW27"
                target="_blank"
                rel="noopener"
              >
                <Img fluid={locImgEdge.node.fluid} alt="Location" />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Contact
