import React from "react"
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core"
import More from "mdi-material-ui/ChevronDoubleRight"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import ContactModal from "./contactModal"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "50px 20px",
    background: "pearlwite",
    borderRadius: "20px",
    marginTop: "20px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
  },
  header: {
    fontFamily: "'Poppins', sans-serif",
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: "2.5rem",
    marginBottom: "20px",
    textAlign: "center",
    textTransform: "capitalize",
  },
  text: {
    fontSize: "1.1rem",
    color: "#555",
    textAlign: "center",
    marginBottom: "40px",
    fontFamily: "'Roboto', sans-serif",
  },
  featureImgContainer: {
    marginTop: "20px",
  },
}))

const useProjectCardStyles = makeStyles((theme) => ({
  projectCard: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "15px",
    height: "100%",
    overflow: "hidden",
    background: "linear-gradient(135deg,rgb(187, 255, 241),rgb(250, 154, 221))",
    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "0 12px 36px rgba(0, 0, 0, 0.2)",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  featureImage: {
    flex: "1 1 40%",
    maxHeight: "2500px",
    minHeight: "350px",
    overflow: "hidden",
    "& img": {
      objectFit: "contain",
      width: "100%",
      height: "100%",
    },
  },
  cardContent: {
    flex: "1 1 60%",
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    fontWeight: 600,
    fontSize: "1.3rem",
    color: theme.palette.primary.main,
  },
  clientDescription: {
    marginTop: "10px",
    color: "#666",
    fontSize: "1rem",
    lineHeight: "1.6",
    fontFamily: "'Roboto', sans-serif",
  },
  actions: {
    marginTop: "20px",
    justifyContent: "space-between",
  },
  button: {
    background: "linear-gradient(225deg, rgb(67, 60, 60) 0%, #784BA0 41%, rgb(36, 73, 105) 100%)",
    color: "#fff",
    borderRadius: "25px",
    textTransform: "none",
    fontWeight: 600,
    padding: "10px 20px",
    "&:hover": {
      background: "linear-gradient(90deg, #FF512F, #DD2476)",
    },
  },
}))

const ProjectCard = ({ slug, featureImage, title, client, description, requestDemo }) => {
  const classes = useProjectCardStyles()
  return (
    <Card className={classes.projectCard}>
      <div className={classes.featureImage}>
        <Img fluid={featureImage.childImageSharp.fluid} alt={title} />
      </div>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Client:</strong> {client}
        </Typography>
        <Typography className={classes.clientDescription}>{description}</Typography>
        <CardActions className={classes.actions}>
          <Button
            className={classes.button}
            endIcon={<More />}
            onClick={() => navigate(slug)}
          >
            Know More
          </Button>
          {requestDemo && <ContactModal buttonText="Request Demo" />}
        </CardActions>
      </CardContent>
    </Card>
  )
}

ProjectCard.propTypes = {
  slug: PropTypes.string.isRequired,
  featureImage: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  requestDemo: PropTypes.bool,
}

ProjectCard.defaultProps = {
  requestDemo: false,
}

const Projects = () => {
  const classes = useStyles()
  const displayFileData = useStaticQuery(graphql`
    query {
      allFile(
        filter: { name: { eq: "display" } }
        sort: { fields: childMarkdownRemark___frontmatter___displayIdx }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            childMarkdownRemark {
              frontmatter {
                client
                title
                description
                requestDemo
                featureImage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const fileEdges = displayFileData.allFile.edges
  return (
    <Grid className={classes.container} container>
       <Typography  className={classes.header}>
          Selected projects from our portfolio
      </Typography>
      <Grid container spacing={4} className={classes.featureImgContainer}>
        {fileEdges.map(
          ({
            node: {
              id,
              fields: { slug },
              childMarkdownRemark: {
                frontmatter: { client, title, description, featureImage, requestDemo },
              },
            },
          }) => (
            <Grid key={id} item xs={12} sm={6} md={6} lg={6}>
              <ProjectCard
                slug={slug}
                featureImage={featureImage}
                title={title}
                client={client}
                description={description}
                requestDemo={requestDemo}
              />
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  )
}

export default Projects
