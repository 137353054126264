import React from "react"
import {
  Typography,
  Grid,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  
} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import ShieldLock from "mdi-material-ui/ShieldLock"
import Close from "mdi-material-ui/Close"
import pdfDisclaimer from "../attachments/disclaimer.pdf"
import pdfDatapolicy from "../attachments/datapolicy.pdf"
import pdfBlockchain from "../attachments/blockchain.pdf"
import { blue } from "@material-ui/core/colors"

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "20px",
    backgroundColor: theme.palette.background.default,
  },
  text: {
    marginTop: "20px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  header: {
    fontFamily: "Patua One",
    color: theme.palette.primary.main,
    marginBottom: 20,
  },
  policyDescriptionText: {
    marginTop: 20,
    color: theme.palette.text.secondary,
  },
  root: {
    display: "flex",
    borderRadius: 10,
    boxShadow: theme.shadows[3],
    transition: "0.3s",
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    background:"linear-gradient(135deg,rgb(244, 132, 188),rgb(129, 194, 251))",
  },
  cover: {
    width: 120,
  },
  policyPointsContainer: {
    marginTop: 5,
  },
  policyPoint: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginLeft: 20,
    paddingTop: 5,
  },
  policyPointBullet: {
    paddingRight: 10,
    alignSelf: "flex-start",
    fontWeight: "bold",
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  modalCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    marginLeft: "10px",
    marginRight: "10px",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

const Security = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [currentPdf, setCurrentPdf] = React.useState("")

  const POLICY_DOCUMENTS = {
    PDFDISCLAIMER: "PDFDISCLAIMER",
    PDFBLOCKCHAIN: "PDFBLOCKCHAIN",
    PDFDATAPOLICY: "PDFDATAPOLICY",
  }

  const getPdf = () => {
    switch (currentPdf) {
      case POLICY_DOCUMENTS.PDFDISCLAIMER:
        return pdfDisclaimer
      case POLICY_DOCUMENTS.PDFBLOCKCHAIN:
        return pdfBlockchain
      case POLICY_DOCUMENTS.PDFDATAPOLICY:
        return pdfDatapolicy
      default:
        return pdfDatapolicy
    }
  }

  const handleOpen = pdf => {
    setCurrentPdf(pdf)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.header}>
          Data Protection & Security
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.root}>
          <ShieldLock
            className={classes.cover}
            style={{
              color:  blue[500],
              fontSize: 75,
              marginTop: 50,
             }}
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography className={classes.text}>
                We place a huge importance on data protection and application
                security. All data shared with us is subjected to a stringent
                SOP to eliminate any chance of data exposure. In addition to the
                best practices we follow, we also work with the customer&apos;s
                teams to comply with any internal standards. Here are some key
                security measures we take to guarantee a safe application
                development:
              </Typography>
              <div className={classes.policyPointsContainer}>
                {[
                  "All data shared is handled by one SPOC and is anonymized before working with it in the dev environment.",
                  "Development team access to the testing environment databases is strictly guarded with user role-based accesses with access expiries in place.",
                  "Each application follows the industry standard security best practices for security. We use Snyk to make sure we handle vulnerabilities against the most recent vulnerability database.",
                  "Our applications are submitted to an independent third-party CERT-in empaneled agency to audit for OWASP vulnerabilities before deployment.",
                  "While we also provide a HIPAA compliant user management, we also integrate (and suggest using) custom SSO logins to use the customer&apos;s existing enterprise login service to maximize security.",
                  "All our data is stored in HIPAA compliant databases and the data residence location is decided by the customer.",
                ].map((point, index) => (
                  <div className={classes.policyPoint} key={index}>
                    <Typography className={classes.policyPointBullet}>
                      {index + 1}.
                    </Typography>
                    <Typography style={{ fontSize: 16 }}>
                      {point}
                    </Typography>
                  </div>
                ))}
              </div>
            </CardContent>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} className={classes.policyDescriptionText}>
        For more details, please refer to our
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={() => handleOpen(POLICY_DOCUMENTS.PDFDATAPOLICY)}
        >
          Data Policy
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={() => handleOpen(POLICY_DOCUMENTS.PDFBLOCKCHAIN)}
        >
          Blockchain Policy
        </Button>
        and our
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={() => handleOpen(POLICY_DOCUMENTS.PDFDISCLAIMER)}
        >
          Disclaimer
        </Button>
        Documents
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Policy
          <IconButton
            aria-label="close"
            className={classes.modalCloseButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "100%", height: "75vh" }}>
            <iframe
              title="policy-document"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              src={`${getPdf()}#toolbar=0&view=FitH`}
            />
          </div>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default Security