import React from "react"
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const HeroSection = () => {
  const sections = [
    {
      title: "Our Core Values",
      content: [
        "Innovation: Driving cutting-edge solutions that push the boundaries of blockchain technology.",
        "Integrity: Building trust through ethical practices, transparency, and honesty.",
        "Customer-Centricity: Prioritizing our clients’ success by understanding their needs and delivering impactful solutions.",
        "Collaboration: Creating value through partnerships, teamwork, and open communication.",
        "Excellence: Upholding the highest standards of quality in every solution we deliver.",
        "Sustainability: Ensuring our work positively impacts businesses, society, and the environment.",
      ],
    },
    {
      title: "Our Principles",
      content: [
        "Secure and Scalable Solutions: We design systems that prioritize security and can grow with our clients’ needs.",
        "Transparency: Embracing blockchain’s ethos by maintaining clarity in processes, communications, and data.",
        "Agility: Adapting quickly to industry trends and evolving client requirements.",
        "Knowledge-Driven Approach: Staying ahead of the curve by investing in research and thought leadership.",
        "Accountability: Taking full responsibility for our commitments, delivering on time, and exceeding expectations.",
      ],
    },
    {
      title: "Our Team Values",
      content: [
        "Collaboration and Teamwork: We believe the best solutions come from working together and leveraging diverse perspectives.",
        "Continuous Learning: Our team is always evolving, learning new technologies, and staying updated on industry advancements.",
        "Empowerment: We foster a culture of trust and autonomy, enabling our team to take ownership and innovate fearlessly.",
        "Inclusivity: We thrive on diversity and inclusiveness, ensuring every voice is heard and valued.",
        "Passion for Technology: We are deeply passionate about blockchain and its potential to transform the world.",
      ],
    },
  ]

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #4a90e2, #9013fe)",
        color: "white",
        textAlign: "left",
        py: { xs: 6, md: 10 },
        px: { xs: 4, md: 10 },
      }}
    >
      <Typography
        variant="h3"
        style={{ fontWeight: "bold", mb: 2,  margin: "1.9rem"  ,marginBottom: "2.9rem" }}
      >
        Why Choose Us?
      </Typography>
      <Typography
        variant="h6"
        component="div" // Changed to `div` to avoid nesting issues
        sx={{
          maxWidth: 800,
          mx: "auto",
          mb: 8,
          textAlign: "left",
          padding: { xs: "0 10px", md: "0 20px" },
          fontSize: { xs: "1rem", md: "1.25rem" },
        }}
      >
        <ol
          style={{
            listStyleType: "none", // Remove default list styling
            counterReset: "custom-counter", // Custom counter for list items
            paddingLeft: 0, // Remove default padding
          }}
        >
          {[
            "Proven Expertise: With 15+ years of combined experience, we are leaders in blockchain innovation and enterprise-grade solutions.",
            "Tailored Solutions: We don’t believe in one-size-fits-all. Every solution is customized to your business goals and challenges.",
            "End-to-End Support: From concept to implementation, we’re with you every step of the way.",
            "Cutting-Edge Technology: We leverage the latest advancements in blockchain, smart contracts, and decentralized systems to create future-proof solutions.",
            "Commitment to Excellence: Our team’s dedication ensures that every project is executed flawlessly, delivering measurable impact.",
            "Global Reach: We have experience working with enterprises and governments worldwide, giving us diverse industry insights.",
          ].map((item, index) => (
            <li
              key={index}
              style={{
                counterIncrement: "custom-counter", // Increment custom counter
                marginBottom: "16px", // Add spacing between list items
                display: "flex",
                alignItems: "flex-start",
                fontSize: "inherit",
                lineHeight: "1.6",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  background:
                    "linear-gradient(135deg,rgb(0, 119, 255),rgb(5, 255, 213))",
                  color: "white",
                  textAlign: "center",
                  lineHeight: "30px",
                  fontWeight: "bold",
                  marginRight: "12px",
                  flexShrink: 0,
                }}
              >
                {index + 1}
              </span>
              {item}
            </li>
          ))}
        </ol>
      </Typography>

      <Box sx={{mt:10, py: 4, px: 2, background: "#f9f9f9" ,borderRadius: "8px", boxShadow: "0 4px 15px rgba(0,0,0,0.1)", transition: "all 0.3s ease-in-out", "&:hover": { boxShadow: "0 6px 20px rgba(0,0,0,0.2)", transform: "translateY(-2px)" } }}>
        {sections.map((section, index) => (
          <Box key={index} sx={{ mb: 2,   mx: "auto" ,borderRadius: "8px", boxShadow: "0 4px 15px rgba(0,0,0,0.1)", background: "linear-gradient(135deg, #ffffff, #f9f9f9)", transition: "all 0.3s ease-in-out", "&:hover": { boxShadow: "0 6px 20px rgba(0,0,0,0.2)", transform: "translateY(-2px)" } }}>
            <Accordion
            defaultExpanded={index === 0}
              fullWidth
              square
              sx={{
                borderRadius: "8px",
                boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                background: "linear-gradient(135deg, #ffffff, #f9f9f9)",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0 6px 20px rgba(0,0,0,0.2)",
                  transform: "translateY(-2px)",
                },
                "&.Mui-expanded": {
                  margin: "16px 0",
                },
              }}
            >
              <AccordionSummary
          style={{
 borderRadius: "8px", boxShadow: "0 4px 15px rgba(0,0,0,0.1)", background: "linear-gradient(135deg, #ffffff, #f9f9f9)", transition: "all 0.3s ease-in-out", "&:hover": { boxShadow: "0 6px 20px rgba(0,0,0,0.2)", transform: "translateY(-2px)" }
          }}                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#4a90e2",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  />
                }
                sx={{
                  "&.Mui-expanded": {
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                  },
                }}
              >
                <Typography style={{ fontWeight: "bold", color: "#333" ,fontSize: { xs: "1.25rem", md: "1.5rem" } ,borderLeft: "4px solid #4a90e2", paddingLeft: "1rem" }}
               
                >
                  {section.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol
                  style={{
                    margin: 0,
                    paddingLeft: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
 
                  }}
                >
                  {section.content.map((item, idx) => {
                    const [boldText, ...rest] = item.split(": ")
                    return (
                      <li key={idx}>
                        <Typography
                          component="span"
                          sx={{
                            fontWeight: "bold",
                            color: "#333",
                            fontSize: { xs: "0.95rem", md: "1rem" },
                          }}
                        >
                          {boldText}:
                        </Typography>{" "}
                        <Typography
                          component="span"
                          style={{
                            color: "#555",
                            fontSize: { xs: "0.9rem", md: "0.95rem" },
                          }}
                        >
                          {rest.join(": ")}
                        </Typography>
                      </li>
                    )
                  })}
                </ol>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const HomePage = () => {
  return (
    <Box>
      <HeroSection />
    </Box>
  )
}

export default HomePage
