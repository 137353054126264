import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import Menu from "mdi-material-ui/Menu"
import PropTypes from "prop-types"
import {
  Slide,
  useScrollTrigger,
  AppBar,
  Toolbar,
  Link,
  List,
  ListItemText,
  ListItem,
  Hidden,
  SwipeableDrawer,
  IconButton,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core"

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  appBar: {
    background: "rgba(30, 28, 28, 0.7)", // Dark background
    color: "#ffffff",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.7)",
  },
  link: {
    color: "#ffffff",
    textDecoration: "none",
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)", // Slight zoom effect
      boxShadow: "0px 4px 20px rgba(255, 255, 255, 0.5)", // Glow effect
    },
  },
  drawer: {
    width: 250,
    background: "#1e1e1e", // Dark drawer background
    color: "#ffffff",
    padding: theme.spacing(2),
  },
  drawerLink: {
    textDecoration: "none",
    color: "#ffffff",
    transition: "color 0.3s ease",
    "&:hover": {
      color: "#1e88e5", // Blue hover effect for drawer links
    },
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.1)", // Subtle divider
  },
}))

const NavBar = () => {
  const classes = useStyles()
  const navItems = [
    { label: "About Us", link: "/#aboutUs" },
    { label: "Projects", link: "/#projects" },
    { label: "Tools & Approach", link: "/#approach" },
    { label: "Team", link: "/#team" },
    { label: "Awards", link: "/#awards" },
    { label: "Reports", link: "/#annualReports" },
    { label: "Contact Us", link: "/#contactUs" },
  ]

  const [drawerOpen, toggleDrawer] = useState(false)
  const drawer = (
    <div
      className={classes.drawer}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <Divider className={classes.divider} />
      <List>
        {navItems.map(navItem => (
          <Link
            key={navItem.link}
            href={navItem.link}
            className={classes.drawerLink}
            variant="button"
          >
            <ListItem button>
              <ListItemText primary={navItem.label} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  )

  return (
    <>
      <HideOnScroll>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => toggleDrawer(true)}
                edge="start"
              >
                <Menu />
              </IconButton>
              <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
              >
                {drawer}
              </SwipeableDrawer>
            </Hidden>
            <Hidden smDown>
  <Grid
    container
    justifyContent="space-evenly" // Distribute links evenly across navbar
    alignItems="center"
    style={{ width: "100%" }} // Ensure the grid spans the full navbar width
  >
    {navItems.map(item => (
      <Grid
        key={item.link}
        item
        style={{ flex: 1, textAlign: "center" }} // Make each item take equal space
      >
        <Link href={item.link} className={classes.link}>
          <Typography variant="button">{item.label}</Typography>
        </Link>
      </Grid>
    ))}
  </Grid>
</Hidden>


          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  )
}

export default NavBar
